<template>
    <section class="sites-list">
        <div class="table-head">
            <div class="main-items">
                <span class="ticket">{{$t('site_name')}}</span>
            </div>
        </div>
        <template v-if="sites.length">
            <div class="items">
                <template v-for="(entry, index) in sites" :key="index">
                    <span v-if="entry.site_details" @click="openSite(entry)">{{entry.site_details.name}}</span>
                </template>
            </div>
        </template>
        <template v-else>
            <span class="empty">
                There are currently no Sites created.
            </span>
        </template>
    </section>
</template>

<script>
export default {
    props: {
        sites: {
            type: Array,
            required: true
        }
    },

    methods: {
        openSite(val) {
            console.log('open site ', val.project_id)
        }
    },
}
</script>

<style lang="scss" scoped>
  .sites-list {
    .table-head {
        height: 40px;
        display: flex;
        align-items: center;
        padding: 10px 25px;
        box-shadow: 0px 1px 6px #CAD6E280;
        border-bottom: solid 1px #F2F5F8;
        background: white;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;

        .main-items {
            width: 98%;
            display: flex;

            span {
                width: 100%;
                display: flex;
                font-size: 0.8rem;
                font-weight: 500;
                text-transform: capitalize;
            }
        }
    }

    .items {
        background: white;
        display: flex;
        flex-direction: column;
        span {
            padding: 10px 25px;
            border-bottom: solid 1px #F2F5F8;
            cursor: pointer;
            font-size: 1rem;
        }
    }
  }
</style>