<template>
    <form v-for="(child, i) in items.options" :key="i" @submit.prevent="testForm">
        <div class="item" v-if="child.type === 'select'">
            <select v-model="data[child.name]" @change="changeOpt(child.relate_to)">
                <option :value="undefined" disabled selected>Select {{child.name}}</option>
                <option v-for="(item, n) in filteredOptions[child.api]" :key="n" :value="item">
                    {{ item.name }}
                </option>
            </select>
        </div>
        <template v-if="childs.length">
            <template v-for="(children, index) in childs" :key="index">
                <div class="item" v-if="children.type === 'select'">
                    <select v-model="data[children.name]" @change="changeOpt(children.relate_to, children.relate_condition, $event)">
                        <option :value="undefined" disabled selected>Select {{children.name}}</option>
                        <option v-for="(item, n) in filteredOptions[children.api]" :key="n" :value="item">
                            {{ item.name }}
                        </option>
                    </select>
                </div>
            </template>
        </template>
        <div class="buttons">
            <button v-if="(created.length && !ready && !createNew) || items.name === 'organization'" class="submit" @click="handleSave">Save User</button>
            <span v-if="createNew" class="submit" @click="saveAdd">Save and Add New Client</span>
        </div>
    </form>
</template>

<script>
import axiosInstance from "@/services/AxiosTokenInstance";
export default {
    props: {
        items: {
            type: Object,
            required: false,
            default: () => {}
        },

        created: {
            type: Array,
            required: false,
            default: () => []
        },
        filledData: {
            type: Object,
            required: false,
            default: () => {}
        },
        toEdit: {
            type: Number,
            required: false,
            default: () => 0
        }
    },

    data() {
        return {
            api: {},
            filteredOptions: {},
            childs: [],
            ready: false,
            createNew: false,
            data: {}
        }
    },

    emits: ['add', 'create'],

    watch: {
        items: {
            immediate: true,
            handler(val) {
                val.options.forEach(element => {
                    this.fetchApi(element.api)
                })
            }
        },

        filledData: {
            deep: true,
            immediate: true,
            handler(val) {
                if(Object.keys(val).length) this.ready = true
                this.data = val
            }
        },

        toEdit(val) {
            this.filteredOptions['new-org-client-list'].push(this.api['new-org-client-list'].filter(el => el.id === val)[0])
        }
    },

    methods: {
        async fetchApi(which) {
			let orgData = JSON.parse(localStorage.getItem('organization'));
			const client_id = this.client ? this.client.id : ''
			const response = await axiosInstance.get(`${which}?org_id=${orgData.id}&clientId=${client_id}&limit=100`);
			const responseData = response.data.data

			if (!responseData.last_page) this.api[which] = responseData
			else {
				this.api[which] = []
				this.api[which].push(...responseData.data)
			}

			if(responseData.last_page && responseData.last_page > 1) {
				for (let i = 2; i <= responseData.last_page; i++) {
					const res = await axiosInstance.get(`${which}?org_id=${orgData.id}&clientId=${client_id}&page=${i}&limit=100`);
					this.api[which].push(...res.data.data.data)
				}
			}

            if (which === 'roles-list') {
                switch (this.items.name) {
                    case 'organization':
                            this.filteredOptions[which] = this.api[which].filter(el => el.id !== 9 && el.id !== 10)
                        break;
                    case 'client':
                            this.filteredOptions[which] = this.api[which].filter(el => el.id === 9 || el.id === 10)
                        break;
                }
            }
            else if (which === 'new-org-client-list') {
                let addedClients = []

                this.created.map(el => el.client).filter(element => {
                    addedClients.push(element.id)
                })

                this.filteredOptions[which] = this.api[which].filter(el => !addedClients.includes(el.id))
            }
			else this.filteredOptions[which] = this.api[which]
		},

        handleSave() {
            this.$emit('create', this.data)
        },

        changeOpt(to, condition, event) {
            this.ready = false
            this.createNew = false
            if(to && (!condition || event?.target.selectedOptions[0]._value.id === condition)) {
                if (!this.childs.some(el => el.name === to[0].name)) {
                    this.childs.push(...to)
                    to.forEach(element => {
                        this.fetchApi(element.api)
                    })
                }
            }
            else if (to && condition && event.target.value?.id !== condition) {
                if (this.childs.some(el => el.name === to[0].name)) {
                    const index = this.childs.indexOf(el => el.name === to[0].name)
                    this.childs.splice(index, 1)
                    this.data[to[0].name] = undefined
                }
                this.ready = true
                if(this.items.options[0].add_new) this.createNew = true
            }
            else {
                this.ready = true
                if(this.items.options[0].add_new) this.createNew = true
            }
        },

        saveAdd() {
            this.$emit('add', this.data)

            if(this.filteredOptions['new-org-client-list']) this.filteredOptions['new-org-client-list'].splice(this.filteredOptions['new-org-client-list'].map(el => el.id).indexOf(this.data.client.id), 1)

            this.ready = false
            this.createNew = false
            this.data = {}
            this.childs = []
        },

        handleNew() {
            this.$emit('add', this.data)


            if(this.filteredOptions['new-org-client-list']) this.filteredOptions['new-org-client-list'].splice(this.filteredOptions['new-org-client-list'].map(el => el.id).indexOf(this.data.client.id), 1)

            this.ready = false
            this.createNew = false
            this.data = {}
            this.childs = []

            this.$emit('create')
        }
    },
}
</script>

<style lang="scss" scoped>
form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 1.5rem;

    .item {
        width: 100%;
        select {
            width: 100%;
            max-width: 25rem;
            box-shadow: none;
            padding: 0.5rem 1rem;
            border: 1px solid #CAD6E2;
            border-radius: 2px;
            &:focus {
                outline: none;
            }
        }
    }

    .buttons {
        width: 100%;
        max-width: 25rem;
        display: flex;
        gap: 0.5rem;
        justify-content: flex-end;
        button, span {
            cursor: pointer;
            width: 100%;
            max-width: 12.25rem;
            padding: 0.5rem 1rem;
            text-align: center;
            transition: 0.2s;
        }

        button {
            background: var(--primary-color);
            color: white;
            &:hover {
                background:  var(--primary-hover);
            }

            &:focus {
                outline: none;
            }
        }

        span {
            background: $grey;
            color: black;
            &:hover {
                background: $grey-hover;
            }
        }
    }
}
</style>