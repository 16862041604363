<template>
  <List
    :title="'Client'"
    :entries="bodyItems"
    :headItems="headItems"
    :hasCheck="true"
    :canCreate="canCreate"
    :loaded="loaded"
    :toFilter="toFilter"
    :tabFilters="tabFilters"
    :isActive="isActive"
    @optionSel="handleOption"
    @create="handleNew"
    @filters="filterByItem"
    @actions="handleAction"
    @search="handleSearch"
  />
  <Pagination
    :entries="entries"
    :currentPage="currentPage"
    :total="total"
    :perPage="perPage"
    :pages.="pages"
    @changePage="handlePageChange"
  />
  <edit-modal
    v-if="editItem"
    :title="`Edit Client`"
    :items="toEdit"
    @close="editItem = false"
    @submitData="editProduct"
  />
</template>

<script>
import { mapGetters } from "vuex";
import { GET_USER_PROFILE_GETTER } from "@/store/storeconstants";
import axiosInstance from "@/services/AxiosTokenInstance";
import List from "@/components/List/Index.vue";
import Pagination from "@/components/List/Pagination.vue";
import timeSince from "@/mixins/timeSince";
import EditModal from "@/components/Ui/CRUD/Edit.vue";
import api from "@/mixins/optionsApi";

export default {
  title() {
    return `Remote Assistance | ${this.title}`;
  },
  data() {
    return {
      title: "Clients",
      entries: [],
      currentPage: 1,
      filteredEntries: [],
      total: 0,
      pages: 0,
      perPage: 10,
      imgURL: process.env.VUE_APP_DO_SPACES,
      loaded: false,
      filter: [],
      canCreate: [4, 5, 6],
      headItems: ["Clients", "Sites", "Status"],
      bodyItems: [],
      editOptions: ["see", "edit", "re-send invite"],
      search_key: "",
      hasOptions: true,
      see: false,
      editItem: false,
      create: false,
      selItem: 0,
      client: localStorage.getItem("client")
        ? JSON.parse(localStorage.getItem("client"))
        : null,
      tabFilters: [
        {
          name: "All Clients",
          filter: "",
          isActive: true,
        },
      ],
      isActive: 0,
    };
  },

  provide() {
    return {
      edit: this.editOptions,
      hasOptions: this.hasOptions,
    };
  },

  components: {
    List,
    Pagination,
    EditModal,
  },

  mixins: [timeSince, api],

  watch: {
    currentPage: {
      immediate: true,
      handler(val) {
        this.getData(val);
      },
    },

    filter() {
      this.getData(1);
    },

    entries() {
      this.fillBodyItems();
    },

    search_key() {
      this.getData(1);
    },
  },

  computed: {
    ...mapGetters("account", {
      getUserProfile: GET_USER_PROFILE_GETTER,
    }),
    currentTotal() {
      return this.total > 10 && this.perPage * this.currentPage < this.total
        ? this.perPage * this.currentPage
        : this.total;
    },
    toFilter() {
      return [
        {
          name: "status",
          options: [
            { id: 1, name: "Active" },
            { id: 2, name: "Inactive" },
          ],
        },
        {
          name: "sites",
          options: this.api("sites"),
        },
      ];
    },
    toEdit() {
      const item = this.entries.find((el) => el.id === this.selItem) || {};
      if (Object.keys(item).length) {
        return [
          {
            type: "image",
            label: "Logo",
            value: item.logo ? this.imgURL + item.logo : null,
          },
          {
            type: "text",
            label: "Name",
            value: item.name,
          },
          {
            type: "text",
            label: "Contact Person Name",
            value: item.contact_person_name,
          },
          {
            type: "text",
            label: "Contact Person Email",
            value: item.contact_person_email,
          },
          {
            type: "text",
            label: "Contact Person Number",
            value: item.contact_person_phone,
          },
          {
            type: "multiple-select",
            label: "Sites",
            options: this.api("sites"),
            value: item.sites
              .filter((element) => element.site_details)
              .map((el) => {
                return {
                  rel_id: el.id,
                  id: el.project_id,
                  name: el.site_details.name,
                  image: null,
                };
              }),
          },
          {
            type: "select",
            label: "Status",
            options: [
              { id: 2, name: "Inactive" },
              { id: 1, name: "Active" },
            ],
            value: {
              id: item.status === 1 ? 1 : 2,
              name: item.status === 1 ? "Active" : "Inactive",
            },
          },
        ];
      }
      return [];
    },
  },
  methods: {
    handlePageChange(to) {
      switch (to) {
        case "less":
          this.currentPage -= 1;
          break;
        case "more":
          this.currentPage += 1;
          break;
      }
    },

    fillBodyItems() {
      this.bodyItems = this.entries.map((item) => {
        const sites = [];
        item.sites.map((el) => {
          if (
            el.site_details &&
            !sites.some((site) => site.name === el.site_details.name)
          )
            sites.push(el.site_details);
        });

        return [
          {
            template: "image",
            id: item.id,
            data: {
              image: item.logo ? this.imgURL + item.logo : null,
              title: item.name,
              description: [
                {
                  light: true,
                  text: `last updated ${this.timeSince(item.updated_at)}`,
                },
              ],
            },
          },
          {
            template: "multiple",
            data: sites,
          },
          {
            template: "normal",
            data: item.status === 1 ? "Active" : "Inactive",
          },
        ];
      });
    },

    async getData(value) {
      this.entries = [];
      this.perPage = 0;
      this.total = 0;
      this.pages = 0;
      this.currentPage = value;
      this.loaded = false;
      let orgDataString = localStorage.getItem("organization");
      if (orgDataString) {
        let response;
        let orgData = JSON.parse(orgDataString);
        if (!this.filter.length)
          response = await axiosInstance
            .get(
              `new-org-client-list?org_id=${orgData.id}&searchKey=${this.search_key}&page=${value}`
            )
            .finally(() => (this.loaded = true));
        else
          response = await axiosInstance
            .get(
              `new-org-client-list?org_id=${orgData.id}&searchKey=${this.search_key}&${this.filter[0]}=${this.filter[1]}&page=${value}`
            )
            .finally(() => (this.loaded = true));
        let responseData = response.data.data;
        this.entries = responseData.data;
        this.perPage = responseData.per_page;
        this.total = responseData.total;
        this.pages = response.data.data.last_page;
      }
    },

    async filterByItem(data) {
      switch (data[0]) {
        case "status":
          this.filter = ["status_id", data[1]];
          break;
        case "sites":
          this.filter = ["site_id", data[1]];
          break;
        case "remove":
          this.filter = [];
          break;
      }
    },

    async createNew(data) {
      const org = JSON.parse(localStorage.getItem("organization"));

      const toSend = {
        org_id: org.id,
        country_id: data.Country.id,
        name: data["Client Name"],
        contact_person_name: data["Leader User Name"],
        email: data["Leader User Email"],
        sites: data.Sites ? data.Sites.map((site) => site.id) : [],
      };

      try {
        const response = await axiosInstance.post(
          `client-registration-invitation`,
          toSend
        );
        if (response) {
          this.handleAlert(response.data.message);
          this.getData(1);
        }
      } catch (e) {
        this.handleAlert("Something went wrong");
      }
    },

    handleAlert(data) {
      this.emitter.emit("alert", data);
    },

    handleOption(data) {
      this.selItem = data[1];
      const item = this.entries.find((el) => el.id === this.selItem) || {};
      switch (data[0]) {
        case "see":
          this.$router.push({
            name: `client`,
            params: { clientid: this.selItem, client: JSON.stringify(item) },
          });
          break;
        case "edit":
          this.editItem = true;
          break;
        case "delete":
          this.deleteProduct();
          break;
        case "re-send invite":
          this.resend(this.selItem);
          break;

        default:
          break;
      }
    },

    async resend(id) {
      const response = await axiosInstance.post(
        "re-send-client-registration-invitation",
        {
          client_id: id,
        }
      );
      if (response) {
        this.handleAlert(response.data.message);
      }
    },

    async editProduct(data) {
      const org = JSON.parse(localStorage.getItem("organization"));
      const item = this.entries.find((el) => el.id === this.selItem) || {};

      let toSend = new FormData();

      toSend.append("org_id", org.id);
      toSend.append("client_id", this.selItem);
      toSend.append("name", data.Name || item.name || "");
      toSend.append("status", data.Status.id === 1 ? 1 : 2);
      toSend.append(
        "contact_person_name",
        data["Contact Person Name"] || item.contact_person_name || ""
      );
      toSend.append(
        "contact_person_phone",
        data["Contact Person Number"] || item.contact_person_phone || ""
      );
      toSend.append(
        "contact_person_email",
        data["Contact Person Email"] || item.contact_person_email || ""
      );

      if (data.Images) {
        toSend.append("logo", data.Images[0].originalFile);
      }

      if (data.newSites) {
        for (let i = 0; i < data.newSites.length; i++) {
          toSend.append(`new_projects[${i}]`, data.newSites[i].id);
        }
      }
      if (data.deletedSites) {
        for (let i = 0; i < data.deletedSites.length; i++) {
          toSend.append(`deleted_projects[${i}]`, data.deletedSites[i].rel_id);
        }
      }

      const response = await axiosInstance.post(`update-client`, toSend);

      if (response) {
        this.handleAlert("Client Updated");
        this.getData(1);
      }

      this.selItem = 0;
    },

    async deleteProduct() {
      const res = await axiosInstance.post(`client/delete`, {
        client_id: this.selItem,
      });

      if (res.status === 200) {
        this.handleAlert(res.data.message);
        this.getData(1);
      }

      this.selItem = 0;
    },

    handleAction(data) {
      if (data.name === "delete selected") {
        data.data.forEach(async (element) => {
          await axiosInstance
            .post(`client/delete`, {
              client_id: element,
              org_id: JSON.parse(localStorage.getItem("organization")).id,
            })
            .finally(() => {
              this.getData(this.currentPage);
            });
        });
      }
    },

    handleSearch(data) {
      this.search_key = data;
    },

    handleNew() {
      this.$router.push({ path: "/data-structure/clients/create" });
    },
  },
};
</script>